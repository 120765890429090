(function () {
  'use strict';

  angular
    .module('dashboard.students')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('dashboard.students', {
        url: '/students/:id',
        templateUrl: 'dashboard/students/students.tpl.html',
        controller: 'StudentsCtrl',
        controllerAs: 'students'
      });
  }
}());
